import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Energyze from "../../../images/media/products/energyze/energyze.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"






const Product = () => (
    <Layout lang={"EN"}>
      <Seo title="Revitalizing eye serum - Energyze" lang={"EN"} />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="lime">
                    <H1>Revitalizing eye serum

                    </H1>
                    <p>Radiance Boost & Youtful Glow </p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Revitalizing eye serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Smoothes & brightens.</li>
                        <li>Reduces dark circles and puffiness. </li>
                        <li>Oxygentates and invigorates skin.</li>
                        <li>Fortifies micriobiom and natural skin protective barrier.</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-rewitalizujace-serum-pod-oczy-15-ml-000000000000407508.html" color="lime">Buy in Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-energyze-rewitalizujace-serum-pod-oczy-15-ml.html" color="lime">Buy in Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-energyze-vitamin-c-rewitalizujace-serum-pod-oczy-15-ml-0107788" color="lime">Buy in Gemini</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176931-Oxygenetic_Energyze_Vitamin_C_serum_rewitalizujace_pod_oczy_15_ml" color="lime">Buy in DOZ</ButtonColor>
                    </Container>
            </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="rewitalizujace serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="lime" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                    <Link to="/en/products/revitalizing-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/oxygenating-sorbet">
                        <StaticImage
                            src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/en/products/regenerating-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/regeneracyjne-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="regeneracyjne serum"
                        />
                    </Link>
                    <Link to="/en/products/normalizing-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/normalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="normalizujace serum"
                        />
                    </Link>

                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="lime">

                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="lime">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                <p>
                                    <strong>OXYGENETIC ENERGYZE VITAMIN C  Revitalizing eye serum</strong>
                                </p>
                                <p>
                                 This ageless skin serum instantly oxygenates the sensitive skin around the eye area, energizes and invigorates for skin cell renewal and stronger protective barrier.
                                </p>
                                <p>
                                With this serum the thin eye area will become soft and supple, deeply hydrated with fine lines and wrinkles visibly smoothed to rejuvenate the eye area and  smooth out eye contour. 
                                </p>
                                <p>
                                This reviving serum will fight puffiness and dark circles and prevent the visible signs of fatigue around the eye area. 

                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/energyze/products/rewitalizujace-serum_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="rewitalizujace-serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>How to use:</strong></p>
                                <p>Apply daily in the morning and evening. Make sure your skin is cleansed before appying this serum. For external use only. Avoid contact with eyes.</p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>15ml</p>
                                <Energyze/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Vitamin C" desc="A powerhouse antioxidant which neutralises free radicals harmful to skin cells. Slows aging process in the body and shields against environmental stressors. Lightens pigementation and revives the skin due to enhanced collagen and elastin synthesis." />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                    <Ingredient name="Synbiotics" desc="A blend of prebiotics and probiotics which fortify microbiom, our natural protective barrier, to protect against environmental stressors. Hydrate, nourish and smooth your skin." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active ingredients">
                <ActiveIngredients>
                    <ActiveIngredient name="Polyphenol" desc="Polyphenol is dervied from esculin which promotes blood microcirculation and strenghtens blood vessels. " />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Effectivness">
                <Effectivness>
                    <EffectivnessCard color="lime" percentage="93" desc="badanych potwierdza, że produkt ogranicza powstawanie zmarszczek wokół oczu"/>
                    <EffectivnessCard color="lime" percentage="100" desc="badanych potwierdza, że regularne stosowanie produktu zmniejsza cienie pod oczami"/>
                    <EffectivnessCard color="lime" percentage="93" desc="badanych potwierdza, że produkt zapobiega utracie elastyczności i jędrności skóry"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
